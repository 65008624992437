import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Popper from '@material-ui/core/Popper';
import * as React from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useAuthenticatedUser } from '../../app/AuthenticatedUserProvider';
import { useAuth } from '../../app/AuthProvider';
import { UserAvatar } from '../Avatar/User';
import { CollapsedTooltip } from '../CollapsedTooltip';
import {
  AvatarWrapper,
  DropdownPaper,
  Primary,
  Secondary,
  StyledUserDropdown,
  Text,
  UserDropdownWrapper,
} from './styled';

export interface UserDropdownProps {
  isCollapsed: boolean;
}

export const UserDropdown: React.FC<UserDropdownProps> = ({ isCollapsed }) => {
  const { spacing, zIndex } = useTheme();
  const { signOut } = useAuth();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>(undefined);

  const openUser = React.useCallback((e: React.MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget), []);
  const closeUser = React.useCallback(() => setAnchorEl(undefined), []);

  const { authenticatedUser } = useAuthenticatedUser();

  const onSignOut = React.useCallback(() => {
    signOut().finally(() => history.push('/'));
  }, [signOut, history]);

  if (!authenticatedUser) {
    return null;
  }

  const { email, name } = authenticatedUser;

  return (
    <UserDropdownWrapper>
      <CollapsedTooltip
        isCollapsed={isCollapsed}
        text="User account"
        subText={
          <div>
            {name && (
              <>
                {name}
                <br />
              </>
            )}
            {email}
          </div>
        }
      >
        <StyledUserDropdown onClick={openUser} tabIndex={0}>
          <AvatarWrapper isCollapsed={isCollapsed}>
            <UserAvatar embedded user={authenticatedUser} />
          </AvatarWrapper>
          {!isCollapsed && (
            <Text>
              {name && (
                <Primary noWrap variant="body1" color="textPrimary" title={name}>
                  {name}
                </Primary>
              )}
              <Secondary noWrap variant="body2" color="textSecondary" title={email}>
                {email}
              </Secondary>
            </Text>
          )}
        </StyledUserDropdown>
      </CollapsedTooltip>
      <Popper
        id="user-dropdown-container"
        anchorEl={anchorEl}
        open={!!anchorEl}
        placement="top-start"
        transition
        style={{ marginBottom: `${spacing(0.5)}px`, zIndex: zIndex.drawer }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={350}>
            <DropdownPaper>
              <ClickAwayListener onClickAway={closeUser}>
                <List dense>
                  <ListItem button component={RouterLink} to="/user/settings" onClick={closeUser}>
                    <ListItemText primary="User settings" />
                  </ListItem>
                  <ListItem button onClick={onSignOut}>
                    <ListItemText primary="Sign out" />
                  </ListItem>
                </List>
              </ClickAwayListener>
            </DropdownPaper>
          </Grow>
        )}
      </Popper>
    </UserDropdownWrapper>
  );
};
