import { ChargePointSummary } from '../../../../../app/ApiGen';
import { roundTo2DecimalPlaces, sumValues } from '../../../../../utils/number';
import { Normalised } from '../../../../../utils/request';
import { isNormalisedSessionWithChargePoint, NormalisedSession } from './types';

export class ChargePointSessionSummary {
  public readonly chargePoint: Normalised<ChargePointSummary>;

  public readonly sessions: NormalisedSession[];

  public readonly currency: string;

  constructor(chargePoint: Normalised<ChargePointSummary>) {
    this.chargePoint = chargePoint;
    this.currency = chargePoint.electricityCost?.currency ?? 'NZD';
    this.sessions = [];
  }

  public addSession(session: NormalisedSession): void {
    if (session.chargePoint.id !== this.chargePoint.id) {
      throw new Error('Cannot add session for a different charge point');
    }
    this.sessions.push(session);
  }

  /**
   * Return the total cost of the session in dollars and cents
   **/
  public get cost(): number {
    const costs = this.sessions.map((session) => (session.totalCost?.amount ? Number(session.totalCost.amount) : 0));
    return roundTo2DecimalPlaces(sumValues(costs));
  }

  /**
   * Return the total energy used in the session in kWh
   **/
  public get totalUsage(): number {
    const totalEnergyUsages = this.sessions.flatMap((session) => session.totalPowerUsage ?? 0);
    return this.convertToKwh(sumValues(totalEnergyUsages));
  }

  private convertToKwh(value: number) {
    return Math.round(value / 1000);
  }

  public get totalCarbonUsage(): string | undefined {
    const carbonUse = this.sessions.flatMap((session) => session.totalCarbonUsage ?? []);
    if (carbonUse.length > 0) {
      return sumValues(carbonUse).toLocaleString(undefined, { maximumFractionDigits: 0 });
    }
    return undefined;
  }
}

type ChargePointId = string;

export function toChargePointSessionSummaries(data: NormalisedSession[]): ChargePointSessionSummary[] {
  const sessionsByChargePointId = data
    .filter(isNormalisedSessionWithChargePoint)
    .reduce<Map<ChargePointId, ChargePointSessionSummary>>((chargePointSessions, session) => {
      const existingSummary = chargePointSessions.get(session.chargePoint.id);
      if (typeof existingSummary === 'undefined') {
        const summary = new ChargePointSessionSummary(session.chargePoint);
        summary.addSession(session);
        chargePointSessions.set(summary.chargePoint.id, summary);
      } else {
        existingSummary.addSession(session);
      }
      return chargePointSessions;
    }, new Map());
  return Array.from(sessionsByChargePointId.values());
}
