// import './wdyr';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as Sentry from '@sentry/browser';
import { Amplify } from 'aws-amplify';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { render } from 'react-dom';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { CategoryScale, Chart, LinearScale, LineElement, registerables, TimeScale } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { AuthProvider } from './app/AuthProvider';
import { RestfulReactProvider } from './app/RestfulReactProvider';
import { Navigation } from './components/Navigation';
import { aws, gtm, sentry } from './config';
import { configureStore } from './store/configure';
import { history } from './utils/history';
import Views from './views';
import { NavigationProvider, Theme } from './design-system';

Sentry.init(sentry);

Amplify.configure(aws);

const store = configureStore();

TagManager.initialize(gtm);

Chart.register(LineElement, zoomPlugin, CategoryScale, LinearScale, TimeScale, ...registerables);

const App = (
  <Provider store={store}>
    <Router history={history}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <AuthProvider>
          <RestfulReactProvider store={store}>
            <Theme>
              <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                <Helmet defaultTitle="Evnex CP-Link" titleTemplate="%s · Evnex CP-Link" />
                <NavigationProvider>
                  <Navigation>
                    <Views />
                  </Navigation>
                </NavigationProvider>
              </SnackbarProvider>
            </Theme>
          </RestfulReactProvider>
        </AuthProvider>
      </MuiPickersUtilsProvider>
    </Router>
  </Provider>
);
render(App, document.getElementById('root'));
