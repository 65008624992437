import { includesSearch } from '../../search';
import { CARBON_UNIT, carbonOffsetFormat, electricityCostFormat, stopReasonMapper } from './format';
import { RemappedSession } from '..';

export function searchStopReason(searchValue: string, { stopReason }: RemappedSession): boolean {
  return includesSearch(stopReasonMapper(stopReason), searchValue);
}

export function searchCarbonOffset(searchValue: string, { carbonOffset }: RemappedSession): boolean {
  return !!carbonOffset && includesSearch(`${carbonOffsetFormat(carbonOffset)} ${CARBON_UNIT}`, searchValue);
}

export function searchElectricityCost(searchValue: string, { electricityCost }: RemappedSession): boolean {
  return includesSearch(electricityCostFormat(electricityCost), searchValue);
}

export function searchTotalCarbonUsage(searchValue: string, { totalCarbonUsage }: RemappedSession): boolean {
  return (
    totalCarbonUsage !== undefined &&
    includesSearch(`${totalCarbonUsage.toLocaleString(undefined, { maximumFractionDigits: 0 })} g CO2`, searchValue)
  );
}
