import countries from 'i18n-iso-countries';
import { Address as AddressType } from '../api';

export const getAddressAsString = ({
  address1,
  address2,
  address3,
  city,
  state,
  postCode,
  country,
}: AddressType): string =>
  [address1, address2, address3, city, state, postCode, countries.getName(country, 'en')].filter(Boolean).join(', ');

export const isAddress = (value: unknown): value is AddressType => {
  const address = value as AddressType;
  return !!address.address1 && !!address.city && !!address.country;
};
