import EvStationIcon from '@material-ui/icons/EvStation';
import React from 'react';
import { useSelector } from 'react-redux';
import { uid } from 'react-uid';
import { ChargePointAvatar } from '../../../../../components/Avatar/ChargePoint';
import {
  ContainerHeader,
  ContainerHeaderLoading,
  HeaderSection,
  Item,
  ItemLoading,
  MenuSection,
  NavigationView,
  Separator,
} from '../../../../../design-system';
import { fullStateSelector } from '../../../../../store/root';
import { getChargePointImage } from '../../../../../utils/chargePoints/getChargePointImage';

const Items: React.FC = () => {
  const {
    organisation: { current: currentOrg, loading: orgLoading },
    chargePoint,
    isAdmin,
    isEvnexAdmin,
  } = useSelector(fullStateSelector);
  const { loading, error, current: currentChargePoint } = chargePoint;

  if (loading || orgLoading) {
    return (
      <>
        <HeaderSection>{() => <ContainerHeaderLoading avatar subText />}</HeaderSection>
        <Separator mb={1} />
        <MenuSection canOverflow>
          {() =>
            Array(6)
              .fill(undefined)
              .map((_, idx) => <ItemLoading key={uid(idx)} />)
          }
        </MenuSection>
      </>
    );
  }

  if (!currentChargePoint || !currentOrg) {
    return (
      <>
        <HeaderSection>
          {() => (
            <ContainerHeader
              avatar={
                <ChargePointAvatar>
                  <EvStationIcon />
                </ChargePointAvatar>
              }
              text={currentChargePoint?.name ?? 'Unknown'}
              subText="Charge point"
            />
          )}
        </HeaderSection>
      </>
    );
  }

  if (error) {
    return (
      <>
        <HeaderSection>
          {() => (
            <ContainerHeader
              avatar={
                <ChargePointAvatar>
                  <EvStationIcon />
                </ChargePointAvatar>
              }
              text={currentChargePoint?.name ?? 'Unknown'}
              subText="Charge point"
            />
          )}
        </HeaderSection>
        <Separator mb={1} />
      </>
    );
  }

  const { vendor, model } = currentChargePoint.details;

  return (
    <>
      <HeaderSection>
        {() => (
          <ContainerHeader
            avatar={
              <ChargePointAvatar src={vendor && model ? getChargePointImage(vendor, model) : undefined}>
                <EvStationIcon />
              </ChargePointAvatar>
            }
            text={currentChargePoint.name}
            subText="Charge point"
          />
        )}
      </HeaderSection>
      <Separator mb={1} />
      <MenuSection canOverflow>
        {() => (
          <>
            <Item
              text="Overview"
              to={`/organisations/${currentOrg.slug}/charge-points/${currentChargePoint.id}/overview`}
            />
            <Item
              text="Sessions"
              to={`/organisations/${currentOrg.slug}/charge-points/${currentChargePoint.id}/sessions`}
            />
            <Item
              text="Charge scheduling"
              to={`/organisations/${currentOrg.slug}/charge-points/${currentChargePoint.id}/charge-scheduling`}
            />
            <Item
              text="Electricity pricing"
              to={`/organisations/${currentOrg.slug}/charge-points/${currentChargePoint.id}/electricity-pricing`}
            />
            <Item
              text="Load management"
              to={`/organisations/${currentOrg.slug}/charge-points/${currentChargePoint.id}/load-management`}
            />
            {(isEvnexAdmin || isAdmin) && (
              <>
                <Item
                  text="Logs"
                  to={`/organisations/${currentOrg.slug}/charge-points/${currentChargePoint.id}/logs`}
                />
                <Item
                  text="Firmware"
                  to={`/organisations/${currentOrg.slug}/charge-points/${currentChargePoint.id}/firmware`}
                />
                <Item
                  text="Advanced"
                  to={`/organisations/${currentOrg.slug}/charge-points/${currentChargePoint.id}/advanced`}
                />
              </>
            )}
            <Item
              text="Settings"
              to={`/organisations/${currentOrg.slug}/charge-points/${currentChargePoint.id}/settings`}
            />
            {isEvnexAdmin && (
              <Item
                text="Diagnostics"
                to={`/organisations/${currentOrg.slug}/charge-points/${currentChargePoint.id}/diagnostics`}
              />
            )}
            {isEvnexAdmin && (
              <Item
                text="LLM Diagnostics"
                to={`/organisations/${currentOrg.slug}/charge-points/${currentChargePoint.id}/llm-diagnostics`}
              />
            )}
          </>
        )}
      </MenuSection>
    </>
  );
};

export const chargePointNav: NavigationView = {
  id: 'chargePoint/container',
  type: 'container',
  Items,
};
