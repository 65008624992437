import Box from '@material-ui/core/Box';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment-timezone';
import React from 'react';
import { LegacyUser, useListOrganisationSessions, UserOrganisation } from '../../../../../app/ApiGen';
import { useAuthenticatedUser } from '../../../../../app/AuthenticatedUserProvider';
import { useCurrentOrganisation } from '../../../../../app/CurrentOrganisationProvider';
import { ColumnFiltersToggle } from '../../../../../components/DataTable/Toolbar';
import { DataErrorHandler } from '../../../../../components/ErrorHandler';
import { ExportCsv, ExportProps } from '../../../../../components/ExportCsv';
import { TableLink } from '../../../../../components/Link';
import { Card, ColumnChanger, ColumnChangerProps, DataTable, Section, Workspace } from '../../../../../design-system';
import { normalise } from '../../../../../utils/request';
import { csvDateFormat, shouldTrackAnalytics } from '../utils';
import { ChargePointSessionSummary, toChargePointSessionSummaries } from './chargePointSessionSummary';
import { isNormalisedSessionWithChargePoint } from './types';
import { sortByTotalCarbonUsage } from './utils';

type AnalyticsReportEvent = 'reimbursement_report_view' | 'reimbursement_report_download';

const trackAnalytics = (event: AnalyticsReportEvent, user?: LegacyUser, currentOrg?: UserOrganisation): void => {
  if (shouldTrackAnalytics(user)) {
    window.dataLayer.push({
      event,
      user_id: user?.id,
      organisation_id: currentOrg?.id,
    });
  }
};

const MonthlyUseByChargerView: React.FC<{ orgSlug: string }> = ({ orgSlug }) => {
  const { authenticatedUser } = useAuthenticatedUser();
  const { organisation } = useCurrentOrganisation();

  React.useEffect(() => {
    trackAnalytics('reimbursement_report_view', authenticatedUser, organisation);
  }, [authenticatedUser, organisation]);

  const [filtering, setFiltering] = React.useState(false);
  const [selectedDate, setDate] = React.useState<Date | null>(moment().subtract(1, 'month').toDate());

  const organisationId = organisation?.id ?? '';

  const { loading, error, data, refetch } = useListOrganisationSessions({
    organisationId,
    queryParams: {
      from: moment(selectedDate).startOf('month').toISOString(),
      to: moment(selectedDate).endOf('month').toISOString(),
    },
  });

  if (error) {
    return <DataErrorHandler error={error} refetch={refetch} description="Unable to load reports" />;
  }

  const exportFileName = `monthly-use-by-charger-${orgSlug}-${selectedDate?.toISOString() ?? ''}`;

  const sessionsWithChargePoint = data ? normalise(data).filter(isNormalisedSessionWithChargePoint) : [];
  const sessionData = toChargePointSessionSummaries(sessionsWithChargePoint);

  return (
    <Workspace maxWidth="xl">
      <Card>
        <Box p={2}>
          <Box>
            <Section
              title="Monthly Use by Charger"
              description="Shows the total usage by each charger over a selected calendar month"
            />
          </Box>
          <DataTable<ChargePointSessionSummary>
            isLoading={loading}
            options={{
              columnsButton: true,
              exportButton: true,
              exportFileName,
              filtering,
            }}
            toolbarProps={{
              search: () => (
                <DatePicker
                  variant="inline"
                  autoOk
                  inputVariant="outlined"
                  InputProps={{ margin: 'dense', style: { margin: 0 } }}
                  openTo="month"
                  views={['month', 'year']}
                  label="Month"
                  value={selectedDate}
                  onChange={(newValue) => {
                    setDate(newValue ? newValue.toDate() : null);
                  }}
                />
              ),
              actions: (props: ColumnChangerProps & ExportProps<ChargePointSessionSummary>) => (
                <>
                  <ColumnChanger
                    columnsButton={props.columnsButton}
                    columns={props.columns}
                    icons={props.icons}
                    onColumnsChanged={props.onColumnsChanged}
                  />
                  <ColumnFiltersToggle filtering={filtering} setFiltering={setFiltering} />
                  <ExportCsv<ChargePointSessionSummary>
                    columns={props.columns}
                    data={props.data}
                    dateFormat={csvDateFormat}
                    exportFileName={props.exportFileName}
                    getFieldValue={props.getFieldValue}
                    icons={props.icons}
                    onDownload={() => trackAnalytics('reimbursement_report_download', authenticatedUser)}
                  />
                </>
              ),
            }}
            columns={[
              {
                title: 'Charge Point Name',
                field: 'chargePoint.name',
                render: (rowData) => (
                  <TableLink to={`/organisations/${orgSlug}/charge-points/${rowData.chargePoint.id}/sessions`}>
                    {rowData.chargePoint.name}
                  </TableLink>
                ),
              },
              {
                title: 'Reference ID',
                field: 'chargePoint.referenceId',
              },
              {
                title: 'Total Sessions',
                field: 'sessions.length',
              },
              {
                title: 'Total Energy (kWh)',
                field: 'totalUsage',
              },
              {
                title: 'Total Cost',
                field: 'cost',
              },
              {
                title: 'Currency',
                field: 'currency',
              },
              {
                title: 'Carbon use (g CO₂)',
                field: 'totalCarbonUsage',
                customSort: sortByTotalCarbonUsage,
              },
            ]}
            data={sessionData}
          />
        </Box>
      </Card>
    </Workspace>
  );
};

export default MonthlyUseByChargerView;
